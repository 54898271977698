import React, { useState, useEffect, useRef } from 'react';

import Layout from '../components/Layout'; 
import internshipStyles from './InternshipCTA.module.css';
import styles from './AppleFAQ.module.css';
import './Applelearn.css';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { Link } from 'react-router-dom';
import './LandingPage.css'; // Make sure this import is present
import ResumeTransform from '../components/ResumeTransform'; 
import TopicDetails from './TopicDetails';
import FeatureShowcase from './FeatureShowcase';
import HeroSection from '../components/HeroSection';
import CourseStructure from './CourseStructure';
import AppleStyleFeatures from './AppleStyleFeatures';
import { useInView } from 'react-intersection-observer';
import AnnouncementBanner from './AnnouncementBanner';

function LandingPage() {
       
  const sectionRef = useRef(null);
  

function App() {
  useEffect(() => {
    const smoothScroll = (e) => {
      e.preventDefault();
      const href = e.currentTarget.getAttribute('href');
      document.querySelector(href).scrollIntoView({
        behavior: 'smooth'
      });
    };

    const links = document.querySelectorAll('a[href^="#"]');
    links.forEach(link => {
      link.addEventListener('click', smoothScroll);
    });

    return () => {
      links.forEach(link => {
        link.removeEventListener('click', smoothScroll);
      });
    };
  }, []);

  // ... rest of your App component
}

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('apple-animate');
          } else {
            entry.target.classList.remove('apple-animate');
          }
        });
      },
      { threshold: 0.1 }
    );

    const section = sectionRef.current;
    if (section) {
      observer.observe(section);
    }

    return () => {
      if (section) {
        observer.unobserve(section);
      }
    };
  }, []);
  document.addEventListener('DOMContentLoaded', () => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('fade-in');
        }
      });
    }, { threshold: 0.1 });
  
    const section = document.querySelector('.apple-scroll-section');
    const textElements = section.querySelectorAll('.text-content h1, .text-content h2');
    const imageContainer = section.querySelector('.image-container');
  
    textElements.forEach((el, index) => {
      el.style.transitionDelay = `${index * 0.2}s`;
      observer.observe(el);
    });
  
    observer.observe(imageContainer);
  });
  const [openQuestion, setOpenQuestion] = useState(null);

  const toggleQuestion = (index) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

  const faqData = [
    {
      question: "What is the duration of the internship program?",
      answer: "The internship program is structured over three exciting months, each focusing on different aspects of learning and skill development. It's a journey of growth and discovery!"
    },
    {
      question: "What can I expect to learn during the first month?",
      answer: "The first month is a knowledge powerhouse! You'll dive into theoretical learning from industry experts, engage in live interactive sessions, clear your doubts, and participate in 4-5 weekly sessions via Zoom or Google Meet. It's like drinking from a firehose of information!"
    },
    {
      question: "How is the second month of the program structured?",
      answer: "Month two is where the rubber meets the road! You'll apply concepts learned in the first month, work on real-time projects with mentor assistance, and complete both individual (7-day sprint) and group (21-day marathon) projects. It's time to put your skills to the test!"
    },
    {
      question: "What support is provided during the third month?",
      answer: "The third month is your launchpad to success! You'll go through multiple mock interview sessions, get hands-on resume building assistance, and receive placement support to prepare you for exciting job opportunities. We're committed to helping you land your dream role!"
    },
    {
      question: "What additional benefits does the program offer?",
      answer: "We've got perks galore! You'll receive internship certificates, access competitive coding opportunities, enjoy community support, and join an exclusive WhatsApp community for networking and additional resources. It's not just an internship; it's a complete growth package!"
    }
  ];

  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 100,
      },
    },
  };

  return (
    <Layout>

        
    {
      <div className="landing-page">
        <AnnouncementBanner/>
       
       < HeroSection />
      

      <TopicDetails />
      <FeatureShowcase />
      <ResumeTransform />

      
      <CourseStructure />
      <section className="bg-blue-50 dark:bg-blue-50">
        <div className="max-w-screen-xl px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-24 lg:px-6">
          
          
          
        <section className={internshipStyles.internshipCTA}>
  <div className={internshipStyles.container}>
    <div className={internshipStyles.content}>
      <h2 className={internshipStyles.title}>
        Start your <span className={internshipStyles.highlight}>Internship Today</span>
      </h2>
      <p className={internshipStyles.description}>
        Transform your career with our guided program
      </p>
      <div className={internshipStyles.priceWrapper}>
        <span className={internshipStyles.oldPrice}>₹2000</span>
        <span className={internshipStyles.newPrice}>₹1000</span>
        <span className={internshipStyles.savingsTag}>Save 50%</span>
      </div>
      <Link to="/apply" className={internshipStyles.ctaButton}>
        Apply Now
      </Link>
    </div>
  </div>
</section>
        
        <div className="max-w-screen-xl px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-24 lg:px-6">
        <div ref={sectionRef} className="apple-style-wrapper">
          <div className="apple-content-container">
            <h2 className="apple-main-heading">Boost your <span className="apple-text-highlight">Coding Ratings</span></h2>
            <div className="apple-flex-layout">
              <div className="apple-image-wrapper">
                <img className="apple-feature-img" src="./images/finaliphone.png" alt="dashboard feature" />
              </div>
              <div className="apple-platform-container">
                <div className="apple-platform-item apple-hacker-rank">
                  
                  <span className="apple-platform-name"> <img src="./images/cf.png" alt="HackerRank" /></span>
                </div>
                <div className="apple-platform-item apple-code-chef">
                  
                  <span className="apple-platform-name"> <img src="./images/logo-color-thumb-removebg-preview.png" alt="HackerRank" /></span>
                </div>
                <div className="apple-platform-item apple-leet-code">
                  
                  <span className="apple-platform-name"> <img src="./images/cc.jpeg" alt="HackerRank" /></span>
                </div>
                <div className="apple-platform-item apple-code-forces">
                  
                  <span className="apple-platform-name"> <img src="./images/1668775330427-removebg-preview.png" alt="HackerRank" /></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        
         
        <AppleStyleFeatures />
          <div className="apple-learn-section">
            <div className="apple-learn-container">
              <div className="apple-learn-content">
                <h2 className="apple-learn-title">
                  <span className="apple-highlight">Learn</span> by doing and taking inspiration from others
                </h2>
                <h3 className="apple-learn-subtitle">
                  Talk to people about design, marketing, web3, and a lot more!
                </h3>
                <p className="apple-learn-description">
                  Upskill by participating in hackathons, live events, and speaker sessions.
                </p>
                
              </div>
              <div className="apple-learn-image-container">
                <img className="apple-learn-image" src="./images/meet.png" alt="People collaborating" />
              </div>
            </div>
          </div>
          




      </div>
      </div>
    </section>
   
    
    <section className={styles.faqSection}>
      <div className={styles.container}>
        <h2 className={styles.title}>
          Frequently Asked <span className={styles.highlight}>Questions</span>
        </h2>
        <div className={styles.faqList}>
          {faqData.map((faq, index) => (
            <div key={index} className={styles.faqItem}>
              <button
                onClick={() => toggleQuestion(index)}
                className={styles.faqQuestion}
              >
                <span>{faq.question}</span>
                <span className={styles.icon}>{openQuestion === index ? '−' : '+'}</span>
              </button>
              {openQuestion === index && (
                <div className={styles.faqAnswer}>
                  <p>{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
    
    </div>}
    
  </Layout>
    

    
  
);
}

export default LandingPage;
     