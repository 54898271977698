import React, { useEffect, useRef } from 'react';
import styles from './TopicDetails.module.css';

const TopicNode = ({ title, isMain }) => (
  <div className={`${styles.node} ${isMain ? styles.main : styles.secondary}`}>
    {title}
    <div className={styles.connector}></div>
  </div>
);

const CppTopicDetails = () => (
  <div className={`${styles.flowchart} ${styles.cppFlowchart}`}>
    <h3 className={styles.flowchartTitle}>C++ & Competitive Programming</h3>
    <TopicNode title="Onboarding" isMain={true} />
    <div className={styles.row}>
      <TopicNode title="C++ Beginner" isMain={false} />
      <TopicNode title="C++ Intermediate" isMain={false} />
    </div>
    <div className={styles.row}>
      <TopicNode title="Online Judges" isMain={false} />
      <TopicNode title="C++ Advanced & CP Template" isMain={false} />
    </div>
    <TopicNode title="Time and Space Complexity" isMain={true} />
    <TopicNode title="Problem Solving & Contest" isMain={true} />
    <div className={styles.row}>
      <TopicNode title="Maths for CP Beginner" isMain={false} />
      <TopicNode title="Maths for CP Intermediate" isMain={false} />
    </div>
    <TopicNode title="Searching & Sorting" isMain={true} />
    <TopicNode title="Problem Solving & Contest" isMain={true} />
    <div className={styles.row}>
      <TopicNode title="C++ STL Beginner" isMain={false} />
      <TopicNode title="C++ STL Advanced" isMain={false} />
    </div>
    <TopicNode title="String Problems" isMain={true} />
    <TopicNode title="Debugging & CP Tricks" isMain={true} />
  </div>
);

const MernTopicDetails = () => (
  <div className={`${styles.flowchart} ${styles.mernFlowchart}`}>
    <h3 className={styles.flowchartTitle}>MERN Stack Development</h3>
    <TopicNode title="Web Development Basics" isMain={true} />
    <div className={styles.row}>
      <TopicNode title="HTML5 & CSS3" isMain={false} />
      <TopicNode title="JavaScript Fundamentals" isMain={false} />
    </div>
    <TopicNode title="Frontend Development" isMain={true} />
    <div className={styles.row}>
      <TopicNode title="React Basics" isMain={false} />
      <TopicNode title="React Hooks & State Management" isMain={false} />
    </div>
    <TopicNode title="Backend Development" isMain={true} />
    <div className={styles.row}>
      <TopicNode title="Node.js & Express.js" isMain={false} />
      <TopicNode title="RESTful API Design" isMain={false} />
    </div>
    <TopicNode title="Database Integration" isMain={true} />
    <div className={styles.row}>
      <TopicNode title="MongoDB Basics" isMain={false} />
      <TopicNode title="Mongoose ODM" isMain={false} />
    </div>
    <TopicNode title="Full Stack Integration" isMain={true} />
    <TopicNode title="Authentication & Authorization" isMain={true} />
    <TopicNode title="Deployment & DevOps Basics" isMain={true} />
  </div>
);

const TopicDetails = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(styles.animate);
          }
        });
      },
      { threshold: 0.1 }
    );

    const connectors = containerRef.current.querySelectorAll(`.${styles.connector}`);
    connectors.forEach((connector) => observer.observe(connector));

    return () => observer.disconnect();
  }, []);

  return (
    <div className={styles.topicDetails} ref={containerRef}>
      <h2 className={styles.title}>Course Structures</h2>
      <div className={styles.flowchartContainer}>
        <CppTopicDetails />
        <MernTopicDetails />
      </div>
    </div>
  );
};

export default TopicDetails;