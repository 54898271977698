import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaSearch, FaSort, FaBookmark, FaTimes, FaShare, FaBriefcase, FaClock, FaCalendarAlt, FaUsers, FaMapMarkerAlt, FaMoneyBillWave, FaExclamationCircle, FaPercent, FaArrowRight, FaStar, FaBuilding, FaFilter } from 'react-icons/fa';
import './JobListings.css';
import Layout from './Layout';

const JobListings = () => {
  const [jobs, setJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('date');
  const [showPopup, setShowPopup] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [filters, setFilters] = useState({
    jobType: '',
    experience: '',
    salary: '',
    remote: false
  });
  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get('/fetch_jobs.php');
        if (response.data.status === 'success') {
          const jobsWithIcons = response.data.data.map(job => ({
            ...job,
            logo: job.logo || `https://ui-avatars.com/api/?name=${encodeURIComponent(job.company)}&background=random&color=fff`,
            link: job.link || '#'
          }));
          setJobs(jobsWithIcons);
        } else {
          throw new Error(response.data.message || 'Failed to fetch jobs');
        }
      } catch (error) {
        console.error('Error fetching jobs:', error);
        setError(error.message || 'Failed to fetch jobs. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchJobs();
  }, []);

  if (isLoading) return <div className="job-listings__loading">Loading exciting opportunities...</div>;
  if (error) return <div className="job-listings__error">{error}</div>;

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSort = (e) => {
    setSortBy(e.target.value);
  };

  const handleApply = (job) => {
    if (job.link && job.link !== '#') {
      window.open(job.link, '_blank');
    } else {
      setSelectedJob(job);
      setShowPopup(true);
    }
  };

  const closePopup = () => {
    setShowPopup(false);
    setSelectedJob(null);
  };

  const handleFilterChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const filteredJobs = jobs.filter(job =>
    (job.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    job.company.toLowerCase().includes(searchTerm.toLowerCase()) ||
    job.skills.some(skill => skill.toLowerCase().includes(searchTerm.toLowerCase()))) &&
    (filters.jobType === '' || job.type === filters.jobType) &&
    (filters.experience === '' || job.experience === filters.experience) &&
    (filters.salary === '' || job.salary >= filters.salary) &&
    (!filters.remote || job.isRemote)
  );

  const sortedJobs = filteredJobs.sort((a, b) => {
    if (sortBy === 'date') {
      return new Date(b.postedDate) - new Date(a.postedDate);
    } else if (sortBy === 'salary') {
      return parseInt(b.salary.replace(/\D/g,'')) - parseInt(a.salary.replace(/\D/g,''));
    } else if (sortBy === 'experience') {
      return parseInt(b.experience) - parseInt(a.experience);
    }
    return 0;
  });

  return (
    <Layout>
      <div className="job-listings">
        <div className="job-listings__header">
          <h1 className="job-listings__title">Discover Your Dream Career</h1>
          <div className="job-listings__search-bar">
            <FaSearch className="job-listings__search-icon" />
            <input
              type="text"
              placeholder="Search jobs, skills, or companies"
              value={searchTerm}
              onChange={handleSearch}
              className="job-listings__search-input"
            />
            <button className="job-listings__filter-btn" onClick={() => setShowFilters(!showFilters)}>
              <FaFilter /> Filters
            </button>
          </div>
          {showFilters && (
            <div className="job-listings__filters">
              <select name="jobType" value={filters.jobType} onChange={handleFilterChange}>
                <option value="">All Job Types</option>
                <option value="Full-time">Full-time</option>
                <option value="Part-time">Part-time</option>
                <option value="Contract">Contract</option>
              </select>
              <select name="experience" value={filters.experience} onChange={handleFilterChange}>
                <option value="">All Experience Levels</option>
                <option value="Entry-level">Entry-level</option>
                <option value="Mid-level">Mid-level</option>
                <option value="Senior">Senior</option>
              </select>
              <select name="salary" value={filters.salary} onChange={handleFilterChange}>
                <option value="">All Salaries</option>
                <option value="50000">$50,000+</option>
                <option value="75000">$75,000+</option>
                <option value="100000">$100,000+</option>
              </select>
              <label>
                <input
                  type="checkbox"
                  name="remote"
                  checked={filters.remote}
                  onChange={handleFilterChange}
                />
                Remote Only
              </label>
            </div>
          )}
          <div className="job-listings__sort">
            <select value={sortBy} onChange={handleSort} className="job-listings__sort-select">
              <option value="date">Sort by Date</option>
              <option value="salary">Sort by Salary</option>
              <option value="experience">Sort by Experience</option>
            </select>
            <FaSort className="job-listings__sort-icon" />
          </div>
        </div>
        <p className="job-listings__results">{sortedJobs.length} exciting opportunities await!</p>
        <div className="job-listings__grid">
          {sortedJobs.map(job => (
            <div key={job.id} className="job-card">
              <div className="job-card__header">
                <img src={job.logo} alt={`${job.company} logo`} className="job-card__logo" />
                <div className="job-card__title-container">
                  <h2 className="job-card__title">{job.title}</h2>
                  <h3 className="job-card__company">{job.company}</h3>
                  <div className="job-card__location">
                    <FaMapMarkerAlt />
                    <span>{job.location}</span>
                  </div>
                </div>
                <div className="job-card__actions">
                  <button className="job-card__action-btn" aria-label="Bookmark">
                    <FaBookmark />
                  </button>
                  <button className="job-card__action-btn" aria-label="Share">
                    <FaShare />
                  </button>
                </div>
              </div>
              <div className="job-card__skills">
                {job.skills.map((skill, index) => (
                  <span key={index} className="job-card__skill">{skill}</span>
                ))}
              </div>
              <div className="job-card__details">
                <div className="job-card__detail">
                  <FaMoneyBillWave />
                  <span className="job-card__detail-label">Salary</span>
                  <span className="job-card__detail-value">{job.salary}</span>
                </div>
                <div className="job-card__detail">
                  <FaBriefcase />
                  <span className="job-card__detail-label">Job Type</span>
                  <span className="job-card__detail-value">{job.type}</span>
                </div>
                <div className="job-card__detail">
                  <FaClock />
                  <span className="job-card__detail-label">Experience</span>
                  <span className="job-card__detail-value">{job.experience}</span>
                </div>
                <div className="job-card__detail">
                  <FaUsers />
                  <span className="job-card__detail-label">Applicants</span>
                  <span className="job-card__detail-value">{job.applicants}</span>
                </div>
                <div className="job-card__detail">
                  <FaBuilding />
                  <span className="job-card__detail-label">Company Website</span>
                  <a href={job.link} target="_blank" rel="noopener noreferrer" className="job-card__detail-value job-card__link">Visit</a>
                </div>
              </div>
              <div className="job-card__footer">
                <div className="job-card__posted">
                  Apply by {job.deadline} • Posted {new Date(job.postedDate).toLocaleDateString()}
                </div>
                <div className="job-card__tags">
                  {job.isRemote && <span className="job-card__tag job-card__tag--remote">Remote</span>}
                  {job.isFeatured && <span className="job-card__tag job-card__tag--featured"><FaStar /> Featured</span>}
                </div>
                <button 
                  className="job-card__apply-btn" 
                  onClick={() => handleApply(job)}
                >
                  Apply Now
                </button>
              </div>
            </div>
          ))}
        </div>
        {showPopup && selectedJob && (
          <div className="job-listings__popup">
            <div className="job-listings__popup-content">
              <button className="popup-close-btn" onClick={closePopup}>
                <FaTimes />
              </button>
              <div className="popup-header">
                <h3>{selectedJob.title}</h3>
                <p>{selectedJob.company}</p>
              </div>
              <div className="popup-body">
                <p>Are you ready to take the next step in your career with {selectedJob.company}?</p>
                <ul className="popup-highlights">
                  <li><FaMoneyBillWave /> Salary: {selectedJob.salary}</li>
                  <li><FaBriefcase /> Job Type: {selectedJob.type}</li>
                  <li><FaMapMarkerAlt /> Location: {selectedJob.location}</li>
                </ul>
                <button className="job-listings__popup-btn" onClick={() => window.location.href = '/apply'}>
                  Start Your Application <FaArrowRight />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default JobListings;