import React, { useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faMapMarkerAlt, faBriefcase, faDollarSign, faCalendarAlt, faLock } from '@fortawesome/free-solid-svg-icons';
import './JobSubmissionForm.css';

function JobSubmissionForm() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const [formData, setFormData] = useState({
    title: '',
    company: '',
    location: '',
    skills: '',
    salary: '',
    job_type: '',
    experience: '',
    deadline: '',
    is_remote: false,
    is_featured: false,
    description: ''
  });

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === 'Kam25hai@123') {
      setIsAuthenticated(true);
    } else {
      alert('Incorrect password. Please try again.');
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/submit_job.php', formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
      if (response.data.success) {
        alert('Job submitted successfully!');
        // Reset form or redirect
      } else {
        alert('Error submitting job: ' + response.data.message);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error submitting job: ' + (error.response?.data?.message || error.message));
    }
  };

  if (!isAuthenticated) {
    return (
      <div className="job-form-container">
        <div className="job-form-header">
          <h2>Job Submission Form</h2>
          <p>Please enter the password to access the form</p>
        </div>
        <form onSubmit={handlePasswordSubmit} className="password-form">
          <div className="form-group">
            <FontAwesomeIcon icon={faLock} className="form-icon" />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter password"
              required
            />
          </div>
          <button type="submit" className="btn-submit">Access Form</button>
        </form>
      </div>
    );
  }

  return (
    <div className="job-form-container">
      <div className="job-form-header">
        <h2>Create an Amazing Job Opportunity</h2>
        <p>Fill in the details below to post your job listing</p>
      </div>
      <form onSubmit={handleSubmit} className="job-submission-form">
        <div className="form-group">
          <FontAwesomeIcon icon={faBriefcase} className="form-icon" />
          <input type="text" name="title" value={formData.title} onChange={handleChange} placeholder="Job Title" required />
        </div>
        <div className="form-group">
          <FontAwesomeIcon icon={faBuilding} className="form-icon" />
          <input type="text" name="company" value={formData.company} onChange={handleChange} placeholder="Company" required />
        </div>
        <div className="form-group">
          <FontAwesomeIcon icon={faMapMarkerAlt} className="form-icon" />
          <input type="text" name="location" value={formData.location} onChange={handleChange} placeholder="Location" required />
        </div>
        <div className="form-group">
          <FontAwesomeIcon icon={faBriefcase} className="form-icon" />
          <input type="text" name="skills" value={formData.skills} onChange={handleChange} placeholder="Skills (comma separated)" required />
        </div>
        <div className="form-group">
          <FontAwesomeIcon icon={faDollarSign} className="form-icon" />
          <input type="text" name="salary" value={formData.salary} onChange={handleChange} placeholder="Salary" required />
        </div>
        <div className="form-group">
          <FontAwesomeIcon icon={faBriefcase} className="form-icon" />
          <select name="job_type" value={formData.job_type} onChange={handleChange} required>
            <option value="">Select Job Type</option>
            <option value="Full-time">Full-time</option>
            <option value="Part-time">Part-time</option>
            <option value="Contract">Contract</option>
            <option value="Freelance">Freelance</option>
          </select>
        </div>
        <div className="form-group">
          <FontAwesomeIcon icon={faBriefcase} className="form-icon" />
          <input type="text" name="experience" value={formData.experience} onChange={handleChange} placeholder="Experience" required />
        </div>
        <div className="form-group">
          <FontAwesomeIcon icon={faCalendarAlt} className="form-icon" />
          <input type="date" name="deadline" value={formData.deadline} onChange={handleChange} required />
        </div>
        <div className="form-group checkbox-group">
          <label>
            <input type="checkbox" name="is_remote" checked={formData.is_remote} onChange={handleChange} />
            Remote
          </label>
          <label>
            <input type="checkbox" name="is_featured" checked={formData.is_featured} onChange={handleChange} />
            Featured
          </label>
        </div>
        
        <button type="submit" className="btn-submit">Post Job</button>
      </form>
    </div>
  );
}

export default JobSubmissionForm;