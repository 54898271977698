import React, { useEffect } from 'react';
import { HashRouter as Router, Route, Routes, Link } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import ApplicationForm from './components/ApplicationForm';
import ContactUs from './components/ContactUs';
import JobListings from './components/JobListings';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';
import FAQPage from './components/FAQPage';
import CallbackRequest from './components/CallbackRequest';
import TopicDetails from './components/TopicDetails';
import JobSubmissionForm from './components/JobSubmissionForm';
import AdminPanel from './components/AdminPanel';
import RefundPolicy from './components/RefundPolicy';
import ExploreCourses from './components/ExploreCourses';

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/" element={<LandingPage />} />
        <Route path="/explore" element={<ApplicationForm />} />
        <Route path="/callback" element={<CallbackRequest />} />
        <Route path="/Terms&conditions" element={<TermsAndConditions />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/jobs" element={<JobListings />} />
        <Route path="/FAQ" element={<FAQPage />} />
        <Route path="/admin1" element={<JobSubmissionForm />} />
        <Route path="/coursestructure" element={<TopicDetails />} />
        <Route path="/admin" element={<AdminPanel/>} />
        <Route path="/refundpolicy" element={<RefundPolicy/>} />
        <Route path="/apply" element={<ExploreCourses/>} />
      </Routes>
    </Router>
  );
}

export default App;