// Navigation.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './Navigation.module.css';

function Navigation() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className={`${styles.navigation} ${isScrolled ? styles.scrolled : ''}`}>
      <nav className={styles.nav}>
        <div className={styles.container}>
          <Link to="/" className={styles.logo}>
            <span className={styles.logoText}>
              <span className={styles.internI}>i</span>
              <span className={styles.internText}>ntern</span>
              <span className={styles.indiaI}>i</span>
              <span className={styles.indiaText}>ndia</span>
            </span>
          </Link>
          <div className={styles.menuContainer}>
            <button
              onClick={toggleMobileMenu}
              className={`${styles.mobileMenuButton} ${isMobileMenuOpen ? styles.open : ''}`}
              aria-label="Toggle menu"
            >
              <span className={styles.menuIcon}></span>
            </button>
            <ul className={`${styles.menu} ${isMobileMenuOpen ? styles.mobileMenuOpen : ''}`}>
              <li><Link to="/" className={styles.menuItem}>Home</Link></li>
              <li><Link to="/jobs" className={styles.menuItem}>Internships</Link></li>
              <li><Link to="/coursestructure" className={styles.menuItem}>Course Structure</Link></li>
              <li><Link to="/contact" className={styles.menuItem}>Contact us</Link></li>
              <li><Link to="/FAQ" className={styles.menuItem}>FAQ</Link></li>
            </ul>
          </div>
          <Link to="/explore" className={styles.applyButton}>Apply Now</Link>
        </div>
      </nav>
    </header>
  );
}

export default Navigation;