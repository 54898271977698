import React from 'react';
import { FaTools } from 'react-icons/fa';
import './Popup.css';

const Popup = ({ isOpen, onClose, message }) => {
  if (!isOpen) return null;

  return (
    <div className="popup-overlay" onClick={onClose}>
      <div className="popup-content" onClick={e => e.stopPropagation()}>
        <FaTools size={40} color="#00e5be" style={{ marginBottom: '1rem' }} />
        <h2>Under Maintenance</h2>
        <p>{message}</p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default Popup;