// ResumeTransform.js
import React, { useState, useEffect, useRef } from 'react';
import styles from './ResumeTransform.module.css';
import { Link } from 'react-router-dom';

const ResumeTransform = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [animationProgress, setAnimationProgress] = useState(0);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1, rootMargin: '0px 0px -10% 0px' }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  useEffect(() => {
    let animationFrame;
    const animate = () => {
      setAnimationProgress((prev) => {
        if (isVisible) {
          return Math.min(prev + 0.03, 1);
        } else {
          return Math.max(prev - 0.03, 0);
        }
      });
      animationFrame = requestAnimationFrame(animate);
    };
    animationFrame = requestAnimationFrame(animate);

    return () => cancelAnimationFrame(animationFrame);
  }, [isVisible]);

  return (
    <section ref={sectionRef} className={`${styles.resumeTransform} ${isVisible ? styles.visible : ''}`}>
      <div className={styles.container}>
        <div className={styles.contentWrapper}>
          <div className={styles.textContent}>
            <h2 className={styles.mainTitle}>
              <span className={styles.highlight}>Transform</span> Your Resume
            </h2>
            <p className={styles.description}>
              Elevate your standard resume into a comprehensive professional portfolio showcasing MERN Stack projects and competitive programming achievements.
            </p>
            <div className={styles.features}>
              {['Detailed Technical Projects', 'MERN Stack Expertise', 'competitive programming achievements'].map((feature, index) => (
                <div 
                  key={index} 
                  className={styles.feature} 
                  style={{ 
                    opacity: animationProgress,
                    transform: `translateY(${(1 - animationProgress) * 20}px)`
                  }}
                >
                  <span>{feature}</span>
                </div>
              ))}
            </div>
            <div 
              className={styles.ctaWrapper} 
              style={{ 
                opacity: animationProgress,
                transform: `translateY(${(1 - animationProgress) * 20}px)`
              }}
            >
              <Link to="/apply" className={styles.ctaButton}>Transform Your Resume</Link>
            </div>
          </div>
          <div className={styles.visualContent}>
            <div className={styles.resumeComparison}>
              {['Before', 'After'].map((type, index) => (
                <div 
                  key={index}
                  className={styles.resume} 
                  style={{ 
                    opacity: animationProgress,
                    transform: `scale(${0.9 + animationProgress * 0.1}) translateY(${(1 - animationProgress) * 30}px)`
                  }}
                >
                  <div className={styles.resumeHeader}>{type}</div>
                  <div className={styles.resumeImageContainer}>
                    <img src={`/images/${type.toLowerCase()}cv1.png`} alt={`${type} Resume`} className={styles.resumeImage} />
                  </div>
                </div>
              ))}
            </div>
            
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResumeTransform;