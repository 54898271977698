// HeroSection.js
import React from 'react';
import styles from './HeroSection.module.css';
import { Link } from 'react-router-dom';

const HeroSection = () => {
  return (
    <div className={styles.heroWrapper}>
      <section className={styles.heroSection}>
        <div className={styles.container}>
          <h1 className={styles.title}>Be Our Intern.</h1>
          <ul className={styles.bulletPoints}>
            <li>Gain hands-on experience with cutting-edge technologies</li>
            <li>Work on real-world projects with industry experts</li>
            <li>Enhance your skills through personalized mentorship</li>
            <li>Build a strong professional network</li>
            <li>Boost your resume with recognized certifications</li>
            <li>Potential for full-time job opportunities</li>
          </ul>
          <div className={styles.ctaContainer}>
            <Link to="/apply" className={styles.ctaPrimary}>Explore Courses</Link>
          </div>
        </div>
        <div className={styles.imageContainer}>
          <img src="/images/newlogo.png" alt="Platform showcase" className={styles.heroImage} />
        </div>
      </section>
    </div>
  );
};

export default HeroSection;