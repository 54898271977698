import React, { useState } from 'react';
import './FAQPage.css';
import Layout from './Layout';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`faq-item ${isOpen ? 'open' : ''}`} onClick={() => setIsOpen(!isOpen)}>
      <div className="faq-question">
        <h3>{question}</h3>
        <span className="faq-icon">{isOpen ? '−' : '+'}</span>
      </div>
      <div className="faq-answer">
        <p>{answer}</p>
      </div>
    </div>
  );
};

const FAQPage = () => {
  const faqs = [
    {
      question: "What is Intern India?",
      answer: "Intern India is a leading platform connecting students and recent graduates with exciting internship opportunities across various industries in India. We aim to bridge the gap between academic learning and professional experience."
    },
    {
      question: "How long are the internships offered through Intern India?",
      answer: "Internship durations vary, but typically range from 2 to 6 months. Some companies may offer shorter or longer internships based on their specific needs and projects."
    },
    {
      question: "Are the internships paid?",
      answer: "Many internships offered through Intern India are paid, but compensation varies by company and position. Some internships may offer stipends, while others might be unpaid but provide valuable experience and academic credit."
    },
    {
      question: "Can international students apply for internships through Intern India?",
      answer: "Yes, international students can apply for internships, but they must ensure they have the necessary visas and work permits. We recommend checking with your university's international student office for guidance."
    },
    {
      question: "How do I apply for internships on Intern India?",
      answer: "To apply, create an account on our website, complete your profile, and browse available internships. When you find a suitable position, click 'Apply' and follow the application instructions provided by the employer."
    },
    {
      question: "Does Intern India provide any training or support during the internship?",
      answer: "While Intern India primarily facilitates connections between interns and companies, we offer resources such as resume building tips, interview preparation guides, and career advice through our blog and webinars."
    },
    {
      question: "Can I apply for multiple internships simultaneously?",
      answer: "Yes, you can apply for multiple internships at once. We encourage you to apply for positions that align with your skills and career goals."
    },
    {
      question: "What happens after I complete my internship?",
      answer: "After completing your internship, you'll receive a certificate of completion. Many interns use this experience to enhance their resumes and some may receive full-time job offers from their host companies."
    },
    {
      question: "How does Intern India ensure the quality of internships offered?",
      answer: "We carefully vet all companies posting internships on our platform, ensuring they provide meaningful work experiences. We also collect feedback from interns to continuously improve our offerings."
    },
    {
      question: "Is there a fee to use Intern India's services?",
      answer: "Intern India is free for students and recent graduates to use. We generate revenue through our partnerships with companies posting internships."
    }
  ];

  return (
    <Layout>
    <div className="faq-page">
      <div className="faq-header">
        <h1>Frequently Asked Questions</h1>
        <p>Everything you need to know about Intern India</p>
      </div>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <FAQItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </div>
    </Layout>
  );
};

export default FAQPage;