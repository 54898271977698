import React, { useState } from 'react';
import axios from 'axios';
import styles from './ContactUs.module.css';

import Layout from './Layout';
import { FaCheck, FaExclamationTriangle, FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';

function ContactUs() {
  const [showPopup, setShowPopup] = useState(false);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');

    const data = new FormData();
    for (const key in formData) {
      data.append(key, formData[key]);
    }
    data.append('send-email', '1');

    try {
      const response = await axios.post('/send.php', data, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      console.log('Full response:', response);

      if (response.data && response.data.message) {
        if (response.data.message.includes('successfully')) {
          setShowPopup(true);
          setFormData({
            fullName: '',
            email: '',
            phone: '',
            message: ''
          });
          setTimeout(() => {
            setShowPopup(false);
          }, 5000);
        } else {
          throw new Error(response.data.message);
        }
      } else {
        throw new Error('Unexpected server response');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setError('There was an error sending your message. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  // Generate confetti elements
  const confettiElements = Array.from({ length: 50 }, (_, index) => (
    <div
      key={index}
      className={styles.confetti}
      style={{
        left: `${Math.random() * 100}%`,
        animationDelay: `${Math.random() * 5}s`,
      }}
    ></div>
  ));


  return (
    <Layout>
    <div className={styles.contactContainer}>
    <div className={styles.contactInfoContainer}>
        <h2 className={styles.contactInfoTitle}>Get in Touch</h2>
        <div className={styles.contactInfoList}>
          <div className={styles.contactInfoItem}>
            <FaEnvelope className={`${styles.contactInfoIcon} ${styles.floatingIcon}`} />
            <span>contact@internindia.in</span>
          </div>
          <div className={styles.contactInfoItem}>
            <FaPhone className={`${styles.contactInfoIcon} ${styles.floatingIcon}`} />
            <span>+91 8769305092</span>
          </div>
          <div className={styles.contactInfoItem}>
            <FaMapMarkerAlt className={`${styles.contactInfoIcon} ${styles.floatingIcon}`} />
            <span>Infocity, Gandhinagar</span>
          </div>
        </div>
      </div>

      <div className={styles.mapContainer}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d5997.76939856012!2d72.63219479247759!3d23.196624632495745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1721576158764!5m2!1sen!2sin"
          width="100%"
          height="300"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Google Maps"
        ></iframe>
      </div>
      <div className={styles.formContainer}>
        <h1 className={styles.title}>Contact Us</h1>
        <p className={styles.subtitle}>We'd love to hear from you!</p>
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.formGroup}>
            <label htmlFor="fullName">Full Name</label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              required
              className={styles.input}
              placeholder="John Doe"
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className={styles.input}
              placeholder="johndoe@example.com"
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="phone">Phone</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
              className={styles.input}
              placeholder="(123) 456-7890"
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
              className={styles.textarea}
              placeholder="Your message here..."
            ></textarea>
          </div>
          <button type="submit" className={styles.submitBtn} disabled={isSubmitting}>
            {isSubmitting ? 'Sending...' : 'Send Message'}
          </button>
        </form>
        {error && (
          <div className={styles.errorMessage}>
            <FaExclamationTriangle className={styles.errorIcon} />
            {error}
          </div>
        )}
      </div>
      {showPopup && (
        <div className={`${styles.popupOverlay} ${styles.visible}`}>
          <div className={styles.popup}>
            {confettiElements}
            <h2 className={styles.popupTitle}>Message Sent!</h2>
            <p className={styles.popupMessage}>Thank you for contacting us. We'll get back to you soon.</p>
            <button className={styles.popupClose} onClick={closePopup}>Close</button>
          </div>
        </div>
      )}
    </div>
    </Layout>
  );
}

export default ContactUs;