import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaPlus, FaEdit, FaTrash, FaSearch, FaSave, FaTimes } from 'react-icons/fa';
import './AdminPanel.css';

function AdminPanel() {
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    company: '',
    location: '',
    skills: '',
    salary: '',
    job_type: '',
    experience: '',
    deadline: '',
    is_remote: false,
    is_featured: false,
    link: '', // Added link field
  });

  useEffect(() => {
    fetchJobs();
  }, []);

  useEffect(() => {
    if (selectedJob) {
      setFormData(selectedJob);
    } else {
      resetForm();
    }
  }, [selectedJob]);

  const fetchJobs = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('/fetch_jobs.php');
      console.log('Server response:', response.data); // Debug log
      if (response.data.status === 'success') {
        setJobs(response.data.data);
      } else {
        throw new Error(response.data.message || 'Failed to fetch jobs');
      }
    } catch (error) {
      console.error('Error fetching jobs:', error);
      setError(error.message || 'Failed to fetch jobs. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/submit_job.php', formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
      if (response.data.success) {
        fetchJobs();
        resetForm();
      } else {
        throw new Error(response.data.message || 'Failed to create job');
      }
    } catch (error) {
      console.error('Error creating job:', error);
      alert('Error creating job: ' + error.message);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const updatedData = {
        ...formData,
        skills: Array.isArray(formData.skills) ? formData.skills.join(',') : formData.skills,
        link: formData.link || '#' // Ensure link is never empty
      };
      console.log('Sending update data:', JSON.stringify(updatedData)); // Detailed debug log
      const response = await axios.put('/job_listings.php', updatedData);
      console.log('Server response:', response.data); // Log server response
      if (response.data.status === "success") {
        fetchJobs();
        resetForm();
        setIsEditing(false);
      } else {
        throw new Error(response.data.message || 'Failed to update job');
      }
    } catch (error) {
      console.error('Error updating job:', error);
      alert('Error updating job: ' + error.message);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this job?')) {
      try {
        const response = await axios.delete(`/job_listings.php?id=${id}`);
        if (response.data.status === "success") {
          fetchJobs();
          resetForm();
        } else {
          throw new Error(response.data.message || 'Failed to delete job');
        }
      } catch (error) {
        console.error('Error deleting job:', error);
        alert('Error deleting job: ' + error.message);
      }
    }
  };

  const handleEdit = (job) => {
    setSelectedJob(job);
    setIsEditing(true);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const resetForm = () => {
    setFormData({
      title: '',
      company: '',
      location: '',
      skills: '',
      salary: '',
      job_type: '',
      experience: '',
      deadline: '',
      is_remote: false,
      is_featured: false,
      link: '', // Reset link field
    });
    setIsEditing(false);
    setSelectedJob(null);
  };

  if (isLoading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="admin-panel">
      <h1 className="admin-panel__title">Job Listings Admin Panel</h1>
      <div className="admin-panel__content">
        <div className="admin-panel__job-list">
          <div className="admin-panel__search-bar">
            <FaSearch className="admin-panel__search-icon" />
            <input
              type="text"
              placeholder="Search jobs..."
              className="admin-panel__search-input"
              // Add search functionality here
            />
          </div>
          <div className="admin-panel__table-container">
            <table className="admin-panel__table">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Company</th>
                  <th>Location</th>
                  <th>Link</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {jobs.map((job) => (
                  <tr key={job.id} className={selectedJob && selectedJob.id === job.id ? 'selected' : ''}>
                    <td>{job.title}</td>
                    <td>{job.company}</td>
                    <td>{job.location}</td>
                    <td>{job.link}</td>
                    <td>
                      <button onClick={() => handleEdit(job)} className="admin-panel__btn admin-panel__btn--edit">
                        <FaEdit /> Edit
                      </button>
                      <button onClick={() => handleDelete(job.id)} className="admin-panel__btn admin-panel__btn--delete">
                        <FaTrash /> Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="admin-panel__job-form">
          <h2 className="admin-panel__form-title">{isEditing ? 'Edit Job' : 'Create New Job'}</h2>
          <form onSubmit={isEditing ? handleUpdate : handleCreate} className="admin-panel__form">
            <div className="admin-panel__form-group">
              <label htmlFor="title">Title</label>
              <input
                type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
                className="admin-panel__input"
              />
            </div>
            <div className="admin-panel__form-group">
              <label htmlFor="company">Company</label>
              <input
                type="text"
                id="company"
                name="company"
                value={formData.company}
                onChange={handleChange}
                required
              />
            </div>
            <div className="admin-panel__form-group">
              <label htmlFor="location">Location</label>
              <input
                type="text"
                id="location"
                name="location"
                value={formData.location}
                onChange={handleChange}
                required
              />
            </div>
            <div className="admin-panel__form-group">
              <label htmlFor="skills">Skills</label>
              <input
                type="text"
                id="skills"
                name="skills"
                value={formData.skills}
                onChange={handleChange}
                required
              />
            </div>
            <div className="admin-panel__form-group">
              <label htmlFor="salary">Salary</label>
              <input
                type="text"
                id="salary"
                name="salary"
                value={formData.salary}
                onChange={handleChange}
                required
              />
            </div>
            <div className="admin-panel__form-group">
              <label htmlFor="job_type">Job Type</label>
              <select
                id="job_type"
                name="job_type"
                value={formData.job_type}
                onChange={handleChange}
                required
              >
                <option value="">Select Job Type</option>
                <option value="Full-time">Full-time</option>
                <option value="Part-time">Part-time</option>
                <option value="Contract">Contract</option>
                <option value="Freelance">Freelance</option>
              </select>
            </div>
            <div className="admin-panel__form-group">
              <label htmlFor="experience">Experience</label>
              <input
                type="text"
                id="experience"
                name="experience"
                value={formData.experience}
                onChange={handleChange}
                required
              />
            </div>
            <div className="admin-panel__form-group">
              <label htmlFor="deadline">Deadline</label>
              <input
                type="date"
                id="deadline"
                name="deadline"
                value={formData.deadline}
                onChange={handleChange}
                required
              />
            </div>
            <div className="admin-panel__form-group admin-panel__form-group--checkbox">
              <label>
                <input
                  type="checkbox"
                  name="is_remote"
                  checked={formData.is_remote}
                  onChange={handleChange}
                  className="admin-panel__checkbox"
                />
                Remote
              </label>
            </div>
            <div className="admin-panel__form-group admin-panel__form-group--checkbox">
              <label>
                <input
                  type="checkbox"
                  name="is_featured"
                  checked={formData.is_featured}
                  onChange={handleChange}
                  className="admin-panel__checkbox"
                />
                Featured
              </label>
            </div>
            <div className="admin-panel__form-group">
              <label htmlFor="link">Application Link</label>
              <input
                type="url"
                id="link"
                name="link"
                value={formData.link}
                onChange={handleChange}
                placeholder="https://example.com/apply"
                className="admin-panel__input"
              />
            </div>
            <div className="admin-panel__form-actions">
              <button type="submit" className="admin-panel__btn admin-panel__btn--submit">
                <FaSave /> {isEditing ? 'Update Job' : 'Create Job'}
              </button>
              <button type="button" onClick={resetForm} className="admin-panel__btn admin-panel__btn--cancel">
                <FaTimes /> Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AdminPanel;