import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Chart from 'react-apexcharts';
import styles from './ExploreCourses.module.css';
import Layout from './Layout';


const courseData = [
  {
    title: "MERN Stack Mastery",
    description: "Forge full-stack web applications with MongoDB, Express.js, React, and Node.js, while honing your competitive coding skills.",
    duration: "14 weeks",
    price: 1000,
    features: [
      "Live coding sessions",
      "Real-world projects",
      "Industry mentorship",
      "Competitive coding challenges",
      "Algorithm optimization",
      "Job placement assistance"
    ],
    icon: "🚀",
    color: "#61DAFB",
    chartData: [80, 90, 70, 85, 75, 95]
  },
  {
    title: "Data Analyst Prodigy",
    description: "Transform raw data into actionable insights using cutting-edge analytics tools and excel in competitive coding challenges.",
    duration: "16 weeks",
    price: 1000,
    features: [
      "Hands-on data projects",
      "Advanced statistical analysis",
      "Data visualization mastery",
      "Competitive coding workshops",
      "Machine learning fundamentals",
      "Industry case studies"
    ],
    icon: "📊",
    color: "#FF6384",
    chartData: [85, 75, 95, 80, 90, 70]
  }
];

const ExploreCourses = () => {
  const [hoveredCourse, setHoveredCourse] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(true), 500);
    return () => clearTimeout(timer);
  }, []);

  const navigate = useNavigate();

  const handleEnroll = (courseTitle) => {
    navigate('/explore', { state: { selectedCourse: courseTitle } });
  };

  const chartOptions = {
    chart: {
      type: 'radar',
      toolbar: { show: false },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: { enabled: true, delay: 150 },
        dynamicAnimation: { enabled: true, speed: 350 }
      }
    },
    xaxis: {
      categories: ['Frontend', 'Backend', 'Database', 'DevOps', 'Algorithms', 'Competitive Coding']
    },
    yaxis: { show: false },
    dataLabels: { enabled: false },
    plotOptions: {
      radar: {
        size: 100,
        polygons: {
          strokeColors: 'rgba(255, 255, 255, 0.1)',
          strokeWidth: 1,
          connectorColors: 'rgba(255, 255, 255, 0.1)',
        }
      }
    },
    colors: ['#ffffff'],
    markers: {
      size: 3,
      colors: ['#ffffff'],
      strokeColor: '#ffffff',
      strokeWidth: 2,
    },
    tooltip: { theme: 'dark' }
  };

  return (
    <Layout>
    <section className={`${styles.exploreCourses} ${isVisible ? styles.visible : ''}`}>
      <div className={styles.container}>
        <h2 className={styles.title}>Explore Our <span className={styles.highlight}>Cutting-Edge Courses</span></h2>
        <div className={styles.courseGrid}>
          {courseData.map((course, index) => (
            <div 
              key={index} 
              className={`${styles.courseCard} ${hoveredCourse === index ? styles.hovered : ''}`}
              style={{ '--course-color': course.color }}
              onMouseEnter={() => setHoveredCourse(index)}
              onMouseLeave={() => setHoveredCourse(null)}
            >
              <div className={styles.courseIcon}>{course.icon}</div>
              <h3 className={styles.courseTitle}>{course.title}</h3>
              <p className={styles.courseDescription}>{course.description}</p>
              <div className={styles.courseMeta}>
                <span className={styles.duration}>{course.duration}</span>
                <span className={styles.price}>₹{course.price}</span>
              </div>
              <div className={styles.chartContainer}>
                <Chart
                  options={chartOptions}
                  series={[{ name: course.title, data: course.chartData }]}
                  type="radar"
                  height={200}
                />
              </div>
              <ul className={styles.featureList}>
                {course.features.map((feature, idx) => (
                  <li key={idx} className={styles.featureItem}>{feature}</li>
                ))}
              </ul>
              <button 
                onClick={() => handleEnroll(course.title)} 
                className={styles.applyButton}
              >
                Enroll Now
                <span className={styles.buttonGlow}></span>
              </button>
            </div>
          ))}
        </div>
      </div>
    </section>
    </Layout>
  );
};

export default ExploreCourses;