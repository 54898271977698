import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import './CallbackRequest.css';

const CallbackRequest = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    preferredTime: '',
  });
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [showConfetti, setShowConfetti] = useState(false);
  const [timeOptions, setTimeOptions] = useState([]);
  const [consoleOutput, setConsoleOutput] = useState([]);
  const formRef = useRef(null);

  useEffect(() => {
    generateTimeOptions();
  }, []);

  const generateTimeOptions = () => {
    const options = ['ASAP'];
    for (let i = 9; i <= 17; i++) {
      options.push(`${i}:00`);
      if (i !== 17) options.push(`${i}:30`);
    }
    setTimeOptions(options);
  };

  const addConsoleMessage = useCallback((message, type = 'info') => {
    const newMessage = { message, type, timestamp: new Date() };
    setConsoleOutput(prev => [...prev, newMessage]);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = 'Name is required';
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
    }
    if (!formData.phone.trim()) {
      newErrors.phone = 'Phone number is required';
    } else if (!/^\d{10}$/.test(formData.phone.replace(/\D/g, ''))) {
      newErrors.phone = 'Phone number is invalid';
    }
    if (!formData.preferredTime) newErrors.preferredTime = 'Preferred time is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);
    addConsoleMessage('Initiating callback request submission...', 'info');
    
    const data = new FormData();
    for (const key in formData) {
      data.append(key, formData[key]);
    }
    data.append('submit-callback-request', '1');

    console.log('Data being sent:', Object.fromEntries(data));

    try {
      setLoadingProgress(0);
      const intervalId = setInterval(() => {
        setLoadingProgress(prev => {
          if (prev >= 90) {
            clearInterval(intervalId);
            return 90;
          }
          return prev + Math.floor(Math.random() * 10) + 1;
        });
      }, 500);

      const response = await axios.post('/send.php', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          addConsoleMessage(`Upload progress: ${percentCompleted}%`, 'info');
        }
      });

      console.log('Full response:', response);

      clearInterval(intervalId);
      setLoadingProgress(100);
      setIsLoading(false);

      if (response.data && response.data.message) {
        if (response.data.message.includes('successfully')) {
          setIsSubmitted(true);
          setShowConfetti(true);
          addConsoleMessage('Callback request submitted successfully!', 'success');

          setTimeout(() => {
            setIsOpen(false);
            setIsSubmitted(false);
            setFormData({ name: '', email: '', phone: '', preferredTime: '' });
            setShowConfetti(false);
            setLoadingProgress(0);
            addConsoleMessage('Form reset for new submission.', 'info');
          }, 5000);
        } else {
          throw new Error(response.data.message);
        }
      } else {
        throw new Error('Unexpected server response');
      }
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false);
      setLoadingProgress(0);
      addConsoleMessage('Error submitting callback request: ' + error.message, 'error');
      alert('There was an error submitting your callback request. Please try again.');
    }
  };

  const renderConfetti = () => {
    return (
      <div className="confetti-container">
        {[...Array(50)].map((_, index) => (
          <div
            key={index}
            className="confetti"
            style={{
              left: `${Math.random() * 100}%`,
              animationDelay: `${Math.random() * 3}s`,
            }}
          ></div>
        ))}
      </div>
    );
  };

  return (
    <>
      <button
        className="callback-request-button neumorphic"
        onClick={() => setIsOpen(!isOpen)}
        aria-label="Request a Callback"
      >
        <span className="callback-icon">📞</span>
        <span className="callback-text">Request A Callback</span>
      </button>
      {isOpen && (
        <div className="callback-popup-overlay">
          <div className="callback-popup neumorphic" ref={formRef}>
            <button
              className="close-popup neumorphic"
              onClick={() => setIsOpen(false)}
            >
              &times;
            </button>
            {!isSubmitted ? (
              <form onSubmit={handleSubmit} className="callback-form">
                <h2>Request a Callback</h2>
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className={errors.name ? 'error' : ''}
                  />
                  {errors.name && <span className="error-message">{errors.name}</span>}
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className={errors.email ? 'error' : ''}
                  />
                  {errors.email && <span className="error-message">{errors.email}</span>}
                </div>
                <div className="form-group">
                  <label htmlFor="phone">Phone Number</label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    className={errors.phone ? 'error' : ''}
                  />
                  {errors.phone && <span className="error-message">{errors.phone}</span>}
                </div>
                <div className="form-group">
                  <label htmlFor="preferredTime">Preferred Callback Time</label>
                  <select
                    id="preferredTime"
                    name="preferredTime"
                    value={formData.preferredTime}
                    onChange={handleInputChange}
                    className={errors.preferredTime ? 'error' : ''}
                  >
                    <option value="">Select a time</option>
                    {timeOptions.map((time) => (
                      <option key={time} value={time}>
                        {time}
                      </option>
                    ))}
                  </select>
                  {errors.preferredTime && <span className="error-message">{errors.preferredTime}</span>}
                </div>
                <button
                  type="submit"
                  className={`submit-button neumorphic ${isLoading ? 'loading' : ''}`}
                  disabled={isLoading || isSubmitted}
                >
                  {isLoading ? 'Submitting...' : isSubmitted ? 'Submitted' : 'Submit Request'}
                </button>
              </form>
            ) : (
              <div className="success-message">
                <h2>Thank You!</h2>
                <p>We have received your request and will call you {formData.preferredTime === 'ASAP' ? 'as soon as possible' : `at ${formData.preferredTime}`}.</p>
              </div>
            )}
          </div>
        </div>
      )}
      {showConfetti && renderConfetti()}
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-content">
            <div className="loading-spinner"></div>
            <div className="loading-text">Submitting Request</div>
            <div className="loading-progress-bar">
              <div className="loading-progress" style={{ width: `${loadingProgress}%` }}></div>
            </div>
            <div className="loading-percentage">{loadingProgress}%</div>
          </div>
        </div>
      )}
    </>
  );
};

export default CallbackRequest;