// src/components/CourseStructure.js
import React from 'react';
import styles from './CourseStructure.module.css';

const CourseStructure = () => {
  return (
    <div className={styles.courseStructure}>
      <div className={styles.container}>
        <h1 className={styles.title}>Internship + Training Course Structure.</h1>
        <p className={styles.subtitle}>
          The course structure is designed to help you understand the internship and training program better.
        </p>
        <div className={styles.content}>
          <div className={styles.textContent}>
            {['1st', '2nd', '3rd'].map((month, index) => (
              <div key={index} className={styles.monthSection}>
                <h2 className={styles.monthTitle}>{month} MONTH</h2>
                <ul className={styles.featureList}>
                  {getMonthFeatures(index).map((feature, i) => (
                    <li key={i} className={styles.featureItem}>
                      <span className={styles.checkmark}>✓</span>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <div className={styles.imageContent}>
            <img src="./images/iphone2.png" alt="Course structure" className={styles.image} />
          </div>
        </div>
      </div>
    </div>
  );
};

const getMonthFeatures = (monthIndex) => {
  const features = [
    [
      "Theoretical learning from Industrial Experts",
      "Live interactive sessions",
      "Doubt clearing sessions",
      "Weekly 4-5 sessions via Zoom or Google Meet"
    ],
    [
      "Application of concepts learned in the first month",
      "Real-time Projects with proper mentor assistance",
      "Minor Project - Individual (07 days)",
      "Major Project - Group (21 days)"
    ],
    [
      "Multiple sessions of Mock interviews",
      "Resume building",
      "Placement Assistance",
      "Guest Lectures"
    ]
  ];
  return features[monthIndex];
};

export default CourseStructure;