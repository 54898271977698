import React, { useState } from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaYoutube } from 'react-icons/fa';
import './Footer.css';
import { Link } from 'react-router-dom';
import Popup from './Popup';

const Footer = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');

  const handleLinkClick = (e, linkName, url) => {
    // List of links that should work normally
    const workingLinks = ['/privacypolicy', '/terms&conditions', '/cookies', '/refundpolicy'];
    
    if (workingLinks.includes(url)) {
      // If it's a working link, let it navigate normally
      return;
    }

    // For other links, prevent default and show popup
    e.preventDefault();
    setPopupMessage(`The ${linkName} page is currently under maintenance. Please check back later.`);
    setIsPopupOpen(true);
  };

  return (
    <footer className="intern-india-footer">
      <div className="footer-content">
        <div className="footer-logo">
          <Link to="/" className="logo-link">
            <span className="logo-text">
              <span className="logo-i">i</span>ntern
              <span className="logo-i2">i</span>ndia
            </span>
          </Link>
        </div>
        
        <div className="footer-links">
          {[
            { title: "Company", links: [
              { name: "About Us", url: "/about" },
              { name: "Our Team", url: "/team" },
              { name: "Careers", url: "/careers" },
              { name: "Contact Us", url: "/contact" }
            ]},
            { title: "For Students", links: [
              { name: "Find Internships", url: "/internships" },
              { name: "Resources", url: "/resources" },
              { name: "Blog", url: "/blog" },
              { name: "Success Stories", url: "/success-stories" }
            ]},
            { title: "For Companies", links: [
              { name: "Post an Internship", url: "/post-internship" },
              { name: "Hire Interns", url: "/hire-interns" },
              { name: "Employer Resources", url: "/employer-resources" },
              { name: "Pricing", url: "/pricing" }
            ]},
            { title: "Legal", links: [
              { name: "Terms And Conditions", url: "/terms&conditions" },
              { name: "Privacy Policy", url: "/privacypolicy" },
              { name: "Cookie Policy", url: "/cookies" },
              { name: "Refund Policy", url: "/refundpolicy" }
            ]}
          ].map((column, index) => (
            <div key={index} className="link-column">
              <h3>{column.title}</h3>
              {column.links.map((link, linkIndex) => (
                <Link 
                  key={linkIndex} 
                  to={link.url} 
                  onClick={(e) => handleLinkClick(e, link.name, link.url)}
                >
                  {link.name}
                </Link>
              ))}
            </div>
          ))}
        </div>
        
        <div className="social-media">
          {[
            { icon: <FaFacebookF />, url: "https://facebook.com/internindia", label: "Facebook" },
            { icon: <FaTwitter />, url: "https://twitter.com/internindia", label: "Twitter" },
            { icon: <FaInstagram />, url: "https://instagram.com/internindia", label: "Instagram" },
            { icon: <FaLinkedinIn />, url: "https://linkedin.com/company/internindia", label: "LinkedIn" },
            { icon: <FaYoutube />, url: "https://youtube.com/internindia", label: "YouTube" }
          ].map((social, index) => (
            <a key={index} href={social.url} aria-label={social.label} onClick={(e) => handleLinkClick(e, social.label, social.url)}>{social.icon}</a>
          ))}
        </div>
        
        <div className="footer-bottom">
          <p>&copy; 2023-24 Intern India™. All Rights Reserved.</p>
          <p>Made with ❤️ in India</p>
        </div>
      </div>
      <Popup 
        isOpen={isPopupOpen} 
        onClose={() => setIsPopupOpen(false)} 
        message={popupMessage} 
      />
    </footer>
  );
};

export default Footer;