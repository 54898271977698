// AppleStyleFeatures.js
import React, { useState, useEffect } from 'react';
import './AppleStyleFeatures.css';

// Import images if they're in the src folder
// import offerLetterImage from '../images/offerletter.png';
// import certificateImage from '../images/INTERNSHIPCERTIFICATE.png';
// import jobGroupImage from '../images/jobgroup.png';
// import codingGroupImage from '../images/codinggroup.png';

const AppleStyleFeatures = () => {
  const [activeFeature, setActiveFeature] = useState('offer-letter');

  const features = {
    'offer-letter': {
      title: '24-Hour Offer Letter',
      content: `Jump-start your career with our lightning-fast offer letter process. Within just 24 hours of completing your registration, you'll receive an official offer letter. This quick turnaround demonstrates our commitment to your professional growth and allows you to begin your internship journey without delay.`,
      image: '/images/offerletter.png', // or use imported image: offerLetterImage,
      highlights: [
        'Instant validation of your skills',
        'Expedited onboarding process',
        'Immediate access to internship resources'
      ]
    },
    'internship-certificate': {
      title: 'Partner Certifications',
      content: `Elevate your resume with prestigious certifications from our industry-leading partners. These unique internship certificates are more than just accolades; they're a testament to your practical skills and hands-on experience. Our company-partnered certification program ensures that your achievements are recognized by top employers.`,
      image: '/images/INTERNSHIPCERTIFICATE.png', // or use imported image: certificateImage,
      highlights: [
        'Industry-recognized certifications',
        'Tailored to specific skill sets',
        'Boosts employability and credibility'
      ]
    },
    'whatsapp-channel': {
      title: 'Premium WhatsApp Channel',
      content: `Stay ahead of the curve with our exclusive WhatsApp channel. This premium service delivers real-time updates on internship opportunities, job openings, and career-enhancing content directly to your phone. Plus, gain access to our curated YouTube tutorials, providing you with a constant stream of learning resources.`,
      image: '/images/jobgroup.png', // or use imported image: jobGroupImage,
      highlights: [
        'Instant notifications for opportunities',
        'Exclusive career tips and insights',
        'Direct access to mentors and peers'
      ]
    },
    'expert-support': {
      title: 'Expert Support',
      content: `Unlock your full potential with our comprehensive expert support system. Whether you're stuck on a coding challenge or seeking career advice, our team of industry professionals is here to guide you. Get your doubts cleared, access exclusive study materials, and receive insider hints for coding contests to give you a competitive edge.`,
      image: '/images/codinggroup.png', // or use imported image: codingGroupImage,
      highlights: [
        '24/7 access to expert mentors',
        'Personalized learning paths',
        'Regular code reviews and feedback'
      ]
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    document.querySelectorAll('.apple-feature-content-wrapper').forEach((wrapper) => {
      observer.observe(wrapper);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className="apple-style-features">
      <div className="apple-content-container">
        <div className="apple-feature-menu">
          {Object.entries(features).map(([key, value]) => (
            <button
              key={key}
              className={`apple-feature-button ${activeFeature === key ? 'active' : ''}`}
              onClick={() => setActiveFeature(key)}
            >
              {value.title}
            </button>
          ))}
        </div>
        <div className="apple-feature-content-wrapper">
          <div className="apple-feature-content">
            <h2 className="apple-feature-title">{features[activeFeature].title}</h2>
            <p className="apple-feature-description">{features[activeFeature].content}</p>
            <ul className="apple-feature-highlights">
              {features[activeFeature].highlights.map((highlight, index) => (
                <li key={`${activeFeature}-highlight-${index}`}>{highlight}</li>
              ))}
            </ul>
           
          </div>
          <div className="apple-feature-image">
            <img src={features[activeFeature].image} alt={features[activeFeature].title} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppleStyleFeatures;