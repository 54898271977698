import React from 'react';
import './TermsAndConditions.css';
import Layout from './Layout';

const TermsAndConditions = () => {
  return (
    <Layout>
    <div className="terms-container">
      <h1>Terms and Conditions</h1>
      <p>Welcome to Intern India!</p>

      <section>
        <p>These terms and conditions outline the rules and regulations for the use of Intern India's Website, located at https://www.internindia.in/.</p>
        <p>By accessing this website we assume you accept these terms and conditions. Do not continue to use Intern India if you do not agree to take all of the terms and conditions stated on this page.</p>
      </section>

      <section>
        <h2>Terminology</h2>
        <p>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "Client", "You" and "Your" refers to you, the person log on this website and compliant to the Company's terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to Intern India. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client's needs in respect of provision of the Company's stated services, in accordance with and subject to, prevailing law of India.</p>
      </section>

      <section>
        <h2>Cookies</h2>
        <p>We use cookies to enhance your experience on our website. By continuing to use our website, you consent to our use of cookies in accordance with our Privacy Policy.</p>
      </section>

      <section>
        <h2>License</h2>
        <p>Unless otherwise stated, Intern India and/or its licensors own the intellectual property rights for all material on Intern India. All intellectual property rights are reserved. You may access this from Intern India for your own personal use subjected to restrictions set in these terms and conditions.</p>
        <p>You must not:</p>
        <ul>
          <li>Republish material from Intern India</li>
          <li>Sell, rent or sub-license material from Intern India</li>
          <li>Reproduce, duplicate or copy material from Intern India</li>
          <li>Redistribute content from Intern India</li>
        </ul>
      </section>

      <section>
        <h2>User Comments</h2>
        <p>Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. Intern India does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of Intern India, its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions.</p>
        <p>Intern India reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.</p>
      </section>

      <section>
        <h2>Hyperlinking to our Content</h2>
        <p>The following organizations may link to our Website without prior written approval:</p>
        <ul>
          <li>Government agencies;</li>
          <li>Search engines;</li>
          <li>News organizations;</li>
          <li>Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and</li>
          <li>System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</li>
        </ul>
      </section>

      <section>
        <h2>Content Liability</h2>
        <p>We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</p>
      </section>

      <section>
        <h2>Reservation of Rights</h2>
        <p>We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amend these terms and conditions and it's linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.</p>
      </section>

      <section>
        <h2>Removal of links from our website</h2>
        <p>If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.</p>
      </section>

      <section>
        <h2>Disclaimer</h2>
        <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:</p>
        <ul>
          <li>limit or exclude our or your liability for death or personal injury;</li>
          <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
          <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
          <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
        </ul>
      </section>

      
      <section>
        <h2>Internship Policies</h2>
        <p>Intern India provides a platform for connecting students and recent graduates with internship opportunities. By using our services, you agree to the following internship-related policies:</p>
        <ul>
          <li>Intern India acts as a facilitator and does not guarantee placement in any internship position.</li>
          <li>All information provided during the application process must be accurate and truthful.</li>
          <li>Interns are responsible for adhering to the policies and requirements set by the host companies.</li>
          <li>Intern India is not responsible for any disputes arising between interns and host companies.</li>
          <li>Internship durations, compensations, and terms are set by the host companies and may vary.</li>
        </ul>
      </section>

      <section>
        <h2>User Accounts</h2>
        <p>When you create an account with us, you must provide information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.</p>
        <p>You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third-party service.</p>
        <p>You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>
      </section>

      <section>
        <h2>Intellectual Property</h2>
        <p>The Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of Intern India and its licensors. The Service is protected by copyright, trademark, and other laws of both India and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Intern India.</p>
      </section>

      <section>
        <h2>Termination</h2>
        <p>We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</p>
        <p>Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.</p>
      </section>

      <section>
        <h2>Limitation of Liability</h2>
        <p>In no event shall Intern India, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</p>
      </section>

      <section>
        <h2>Governing Law</h2>
        <p>These Terms shall be governed and construed in accordance with the laws of India, without regard to its conflict of law provisions.</p>
        <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.</p>
      </section>

      <section>
        <h2>Changes to Terms</h2>
        <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
        <p>By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.</p>
      </section>
      <section>
        <h2>Contact Us</h2>
        <p>If you have any questions about these Terms, please contact us:</p>
        <ul>
    <li>By email: support@internindia.in</li>
    <li>By visiting this page on our website: https://www.internindia.in/contact</li>
    <li>By phone number: +91 8769305092</li>
    <li>By mail: Infocity, Gandhinagar, gujrat ,India 123456</li>
  </ul>
      </section>
    </div>
    </Layout>
  );
};

export default TermsAndConditions;