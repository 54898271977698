// AnnouncementBanner.jsx
import React, { useState, useEffect, useRef } from 'react';
import './AnnouncementBanner.css';

const announcements = [
  { text: "Batch 1.0 Starts in 15 days", icon: "🤖", buttonText: "Enroll Now", buttonLink: "#/apply" },
  { text: "Learn Competitive Programming", icon: "🤖", buttonText: "Enroll Now", buttonLink: "#/apply" },
  { text: "Apply For Jobs on InternIndia!", icon: "💻", buttonText: "Apply Now", buttonLink: "#/jobs" },
  { text: "Limited Time Offer: 50% Off", icon: "🎉", buttonText: "Get Discount", buttonLink: "#/apply" }
];

const AnnouncementBanner = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const bannerRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % announcements.length);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  const currentAnnouncement = announcements[currentIndex];

  return (
    <div className="announcement-banner-wrapper">
      <div className="announcement-banner" ref={bannerRef}>
        <div className="announcement-content">
          <span className="announcement-icon">{currentAnnouncement.icon}</span>
          <p className="announcement-text" data-text={currentAnnouncement.text}>
            {currentAnnouncement.text}
          </p>
          <a href={currentAnnouncement.buttonLink} className="announcement-button">
            {currentAnnouncement.buttonText}
          </a>
        </div>
      </div>
    </div>
  );
};

export default AnnouncementBanner;