import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './ApplicationForm.css';
import { FaGraduationCap, FaBriefcase, FaCertificate, FaUsers } from 'react-icons/fa';
import { motion } from 'framer-motion';
import Layout from './Layout';
import axios from 'axios';

function ApplicationForm() {
  const location = useLocation();
  const selectedCourse = location.state?.selectedCourse || '';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({ 
    fullName: '', 
    email: '', 
    phone: '',
    courseType: selectedCourse
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [loadingProgress, setLoadingProgress] = useState(0);
  const successRef = useRef(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');

    const data = new FormData();
    for (const key in formData) {
      data.append(key, formData[key]);
    }
    data.append('submit-application', '1');

    console.log('Data being sent:', Object.fromEntries(data));

    try {
      setLoadingProgress(0);
      const intervalId = setInterval(() => {
        setLoadingProgress(prev => {
          if (prev >= 90) {
            clearInterval(intervalId);
            return 90;
          }
          return prev + Math.floor(Math.random() * 10) + 1;
        });
      }, 500);

      const response = await axios.post('/send.php', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          console.log(`Upload progress: ${percentCompleted}%`);
        }
      });

      console.log('Full response:', response);

      clearInterval(intervalId);
      setLoadingProgress(100);

      if (response.data && response.data.message) {
        if (response.data.message.includes('successfully')) {
          setIsSubmitted(true);
          console.log('Application submitted successfully!');
        } else {
          throw new Error(response.data.message);
        }
      } else {
        throw new Error('Unexpected server response');
      }
    } catch (error) {
      console.error('Error:', error);
      setLoadingProgress(0);
      setError('An error occurred. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (isSubmitted && successRef.current) {
      successRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isSubmitted]);

  const benefitItems = [
    { icon: <FaGraduationCap />, title: "Expert Mentorship", description: "Learn from industry leaders" },
    { icon: <FaBriefcase />, title: "Hands-on Experience", description: "Work on real-world projects" },
    { icon: <FaCertificate />, title: "Certification", description: "Earn a valuable credential" },
    { icon: <FaUsers />, title: "Network Building", description: "Connect with peers and professionals" },
  ];

  return (
    <div className="application-form">
      <Layout>
        <div className="sleek-form-container">
          <motion.div 
            className="sleek-form-header"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7, ease: "easeOut" }}
          >
            <h1>Innovative Internship Program</h1>
            <p>Unleash your potential with cutting-edge excellence</p>
          </motion.div>

          <div className="sleek-form-content">
            <motion.div 
              className="sleek-form-benefits"
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.7, delay: 0.2, ease: "easeOut" }}
            >
              <h2>What You'll Gain</h2>
              <ul>
                {benefitItems.map((item, index) => (
                  <motion.li 
                    key={index} 
                    className="sleek-form-benefit-item"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                    whileHover={{ scale: 1.05, transition: { duration: 0.2 } }}
                  >
                    <span className="sleek-form-benefit-icon">{item.icon}</span>
                    <div>
                      <h3>{item.title}</h3>
                      <p>{item.description}</p>
                    </div>
                  </motion.li>
                ))}
              </ul>
            </motion.div>

            <motion.div 
              className="sleek-form-application"
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.7, delay: 0.2, ease: "easeOut" }}
            >
              <div className="sleek-form-course-info">
                <h2>InternIndia 1.0</h2>
                <div className="sleek-form-price">
                  <span className="sleek-form-amount">₹1000</span>
                  <span className="sleek-form-details">Inclusive of GST</span>
                </div>
              </div>
              {isSubmitted ? (
                <motion.div 
                  ref={successRef} 
                  className="sleek-form-success"
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className="sleek-form-success-icon">✓</div>
                  <h3>Application Submitted!</h3>
                  <p>Thank you for your interest. We'll be in touch soon.</p>
                </motion.div>
              ) : (
                <form onSubmit={handleSubmit} className="sleek-form">
                  {['fullName', 'email', 'phone', 'courseType'].map((field) => (
                    <motion.div 
                      key={field} 
                      className="sleek-form-group"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      {field === 'courseType' ? (
                        <select
                          id={`sleek-form-${field}`}
                          name={field}
                          value={formData[field]}
                          onChange={handleChange}
                          required
                          className="sleek-form-input"
                        >
                          <option value="">Select a course</option>
                          <option value="MERN Stack Mastery">MERN Stack Mastery</option>
                          <option value="Data Analyst Prodigy">Data Analyst Prodigy</option>
                        </select>
                      ) : (
                        <input
                          type={field === 'email' ? 'email' : field === 'phone' ? 'tel' : 'text'}
                          id={`sleek-form-${field}`}
                          name={field}
                          value={formData[field]}
                          onChange={handleChange}
                          required
                          placeholder=" "
                          className="sleek-form-input"
                        />
                      )}
                      <label htmlFor={`sleek-form-${field}`} className="sleek-form-label">
                        {field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1')}
                      </label>
                    </motion.div>
                  ))}
                  <motion.button 
                    type="submit" 
                    className="sleek-form-submit"
                    disabled={isSubmitting}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    {isSubmitting ? 'Processing...' : 'Apply Now'}
                  </motion.button>
                </form>
              )}
              {error && <div className="sleek-form-error">{error}</div>}
            </motion.div>
          </div>
        </div>
        {isSubmitting && (
          <div className="loading-overlay">
            <div className="loading-content">
              <div className="loading-spinner"></div>
              <div className="loading-text">Submitting Application</div>
              <div className="loading-progress-bar">
                <div className="loading-progress" style={{ width: `${loadingProgress}%` }}></div>
              </div>
              <div className="loading-percentage">{loadingProgress}%</div>
            </div>
          </div>
        )}
      </Layout>
    </div>
  );
}

export default ApplicationForm;