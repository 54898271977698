import React from 'react';
import './FeatureShowcase.css';

const FeatureShowcase = () => {
  return (
    <section className="feature-showcase">
      <h2 className="feature-showcase__title">Get to know InternIndia.</h2>
      
      <div className="feature-showcase__scroll">
        <div className="feature-card">
          <span className="feature-card__label">Learn</span>
          <h3 className="feature-card__title">Competitive programming.</h3>
          <div className="feature-card__logos">
  <span className="feature-card__logo-text hackerrank">HackerRank</span>
  <span className="feature-card__logo-text codechef">CodeChef</span>
  <span className="feature-card__logo-text leetcode">LeetCode</span>
  <span className="feature-card__logo-text codeforces">CODEFORCES</span>
  <span className="feature-card__logo-text topcoder">topcoder</span>
</div>
  </div>
        
        
        
        <div className="feature-card">
          <span className="feature-card__label">Fast-Track Your Career</span>
          <h3 className="feature-card__title">Get Your Offer Letter within 24 Hours of registration</h3>
        </div>
        
        <div className="feature-card">
          <span className="feature-card__label">Certifications</span>
          <h3 className="feature-card__title">Unique internship certificates from partners</h3>
          <ul className="feature-card__list">
            <li>Company-partnered certification program</li>
            <li>Boost your resume with recognized achievements</li>
          </ul>
        </div>

        <div className="feature-card">
          <span className="feature-card__label">Stay Connected</span>
          <h3 className="feature-card__title">Premium WhatsApp Channel for Internship and Job Notifications</h3>
          <p className="feature-card__description">Access YouTube Tutorials and stay updated with the latest opportunities.</p>
        </div>

        <div className="feature-card">
          <span className="feature-card__label">Expert Support</span>
          <h3 className="feature-card__title">Clear your doubts with experts</h3>
          <ul className="feature-card__list">
            <li>Get Access to our exclusive Material</li>
            <li>Hints for all contests here</li>
            <li>Personalized guidance for your career growth</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default FeatureShowcase;