import React from 'react';
import Navigation from './Navigation';
import Footer from './Footer';
import CallbackRequest from './CallbackRequest';
import AnnouncementBanner from './AnnouncementBanner';


function Layout({ children }) {
  return (
    <div className="flex flex-col min-h-screen pt-16 ">
      <Navigation />
      
      <CallbackRequest/>

      <main className="flex-grow">
        {children}
      </main>
      <Footer />
    </div>
  );
}

export default Layout;