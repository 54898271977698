import React, { useEffect } from 'react';
import './RefundPolicy.css';
import Layout from './Layout';

function RefundPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <div className="refund-policy-container">
        <h1>Refund Policy – InternIndia</h1>
        <p>
          At InternIndia, we are committed to providing high-quality internship programs and educational experiences. 
          Please read our refund policy carefully before making any payments.
        </p>

        <h2>No Refund Policy</h2>
        <p>
          InternIndia does not provide refunds for any payments made towards our internship programs or courses. 
          Once a payment has been processed, it is non-refundable.
        </p>

        <h2>Exceptions for Payment Issues</h2>
        <p>
          The only circumstance under which a refund may be considered is in the case of payment issues. 
          This includes:
        </p>
        <ul>
          <li>Double charging due to technical errors</li>
          <li>Incorrect amount charged</li>
          <li>Payment processed but program access not granted</li>
        </ul>

        <h2>Refund Process for Payment Issues</h2>
        <p>
          If you believe you have experienced a payment issue as described above, please follow these steps:
        </p>
        <ol>
          <li>Contact our support team at support@internindia.in within 7 days of the payment date</li>
          <li>Provide detailed information about the payment issue, including transaction ID and date</li>
          <li>Our team will investigate the issue and respond within 5 business days</li>
          <li>If a refund is approved, it will be processed to the original payment method within 10 business days</li>
        </ol>

        <h2>No Refunds for Other Reasons</h2>
        <p>
          We do not offer refunds for any other reasons, including but not limited to:
        </p>
        <ul>
          <li>Change of mind</li>
          <li>Dissatisfaction with the program content</li>
          <li>Inability to attend or complete the program</li>
          <li>Technical issues on the user's end</li>
        </ul>

        <h2>Program Modifications</h2>
        <p>
          InternIndia reserves the right to modify, reschedule, or cancel programs. In the event of a program 
          cancellation by InternIndia, participants will be offered the option to transfer to another program 
          or receive credit for future use.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions about our refund policy, please contact us at support@internindia.in.
        </p>
      </div>
    </Layout>
  );
}

export default RefundPolicy;